.title{
    font-weight:bolder;
}
.register-form{
    background-color: white;
    width:75vw;
    margin:0 auto;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.background{
    overflow-x: hidden;
    overflow-y: auto;
}

.form{
    width:70%;
    margin:0 auto;
    padding-bottom: 40px;
    padding-top: 20px;
}

.logobox{
   width:100%;
   text-align: center;
}

.logo{
   width: 10rem;
   max-width: 15%;
   min-width: 10%;
   margin-top: 20px;
}

h3{
    font-weight:bolder;
    padding-top: 10px;
    margin-bottom: 10px;
    color: #000F46;
}

.MuiMenu-list {
    max-height: 40vh;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined {
    background-color: white;
}

@media screen and (max-width: 512px) { 
    .register-form{
        background-color: white;
        width:100%;
        margin:0 auto;
    }
  }
