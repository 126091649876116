body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

code {
  font-family: 'Source Sans Pro', sans-serif;
}

#root h1, #root h2, #root h3, #root h4 {
  color: #000F46;
}

h1.beta:after{
  content: "BETA";
  vertical-align: super;
  font-size: 0.3em;
  font-weight: normal;
}

@media screen and (width >= 512px) {
  .landing .menu_icon {
    display: none;
  }
}

@media screen and (width < 512px) {
  .landing .menu_buttons {
    display: none;
  }

  .landing .landing_header {
    display: none;
  }

  .landing .home_header {
    font-size: 40px !important;
  }
}